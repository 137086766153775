import React, { useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import './ShareModal.css';

import { useGame } from '../../../../../../Context/GameContext';
import { usePoints } from '../../../../../../Context/PointsContext';
import { useAlert } from '../../../../../../Context/AlertContext';
import { useAuth } from '../../../../../../Context/AuthContext';

import { createShareStat, incrementUserShareCount } from '../../../../../../services/firestoreService';

import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import CheckIcon from '@mui/icons-material/Check';

interface ShareModalProps {
  correctAnswer: string;
  onClose: () => void;
}

const ShareModal: React.FC<ShareModalProps> = ({ correctAnswer, onClose }) => {
  const { gameMode, gameDifficulty } = useGame();
  const { showAlert } = useAlert();
  const { previousGameStreak, previousGamePoints, highscoreStreak } = usePoints();
  const { user } = useAuth();
  
  const shareCardRef = useRef<HTMLDivElement>(null);
  
  const [copied, setCopied] = useState(false);

  const [clipboardShareRecorded, setClipboardShareRecorded] = useState(false);
  const [imageShareRecorded, setImageShareRecorded] = useState(false);

  const generateShareText = () => {
    const gameModeName = gameMode.charAt(0).toUpperCase() + gameMode.slice(1);
    const difficultyName = gameDifficulty.charAt(0).toUpperCase() + gameDifficulty.slice(1);
    
    const streakEmojis = '🟩'.repeat(previousGameStreak) + '🟥';

    console.log(streakEmojis, previousGameStreak);
    
    return `🌍 Nationality Guesser\n${streakEmojis}\nStreak: ${previousGameStreak}\nPoints: ${previousGamePoints}\nMode: ${gameModeName} (${difficultyName})\nWrong Answer: ${correctAnswer}\n\nPlay at https://nationalityguesser.com`;
  };

  const handleCopyToClipboard = async () => {
    const shareText = generateShareText();
    try {
      await navigator.clipboard.writeText(shareText);
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
      showAlert('Text copied to clipboard!', 'green');

      if (user && !clipboardShareRecorded) {
        try {
          await createShareStat(
            user.uid,
            user.username,
            user.userType,
            user.subscription,
            'copyClipboard',
            gameMode,
            gameDifficulty,
            previousGameStreak,
            previousGamePoints
          );
          await incrementUserShareCount(user.uid);
          setClipboardShareRecorded(true);
        } catch (error) {
          console.error('Error recording share statistics:', error);
        }
      }
    } catch (error) {
      console.error('Failed to copy text:', error);
      showAlert('Failed to copy text, please try again later.', 'red');
    }
  };

  const handleDownloadImage = async () => {
    if (shareCardRef.current) {
      try {
        const canvas = await html2canvas(shareCardRef.current, {
          backgroundColor: '#25272A',
          scale: 2,
        });
        
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = 'nationality-guesser-result.png';
        link.click();
        showAlert('Image downloaded successfully!', 'green');

        if (user && !imageShareRecorded) {
          try {
            await createShareStat(
              user.uid,
              user.username,
              user.userType,
              user.subscription,
              'downloadImage',
              gameMode,
              gameDifficulty,
              previousGameStreak,
              previousGamePoints
            );
            await incrementUserShareCount(user.uid);
            setImageShareRecorded(true);
          } catch (error) {
            console.error('Error recording share statistics:', error);
          }
        }
      } catch (error) {
        console.error('Error generating image:', error);
        showAlert('Error generating image, please try again later.', 'red');
      }
    }
  };

  return (
    <div className="share-modal-overlay" onClick={onClose}>
      <div className="share-modal-content" onClick={(e) => e.stopPropagation()}>

        <div className="share-modal-header">
          <h2>Share Your Result</h2>
          <button className="close-button" onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        
        <div className="share-card" ref={shareCardRef}>
          <div className="share-header">
            <h3>🌍 Nationality Guesser</h3>
          </div>
          
          <div className="share-stats">
            <div className="stat-item">
              <span className="stat-label">Streak:</span>
              <span className="stat-value">{previousGameStreak}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Best Streak:</span>
              <span className="stat-value">{highscoreStreak}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Points:</span>
              <span className="stat-value">{previousGamePoints}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Game Mode:</span>
              <span className="stat-value">{gameMode}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Difficulty:</span>
              <span className="stat-value">{gameDifficulty}</span>
            </div>
            <div className="stat-item">
              <span className="stat-label">Wrong Answer:</span>
              <span className="stat-value incorrect">{correctAnswer}</span>
            </div>
          </div>
          
          <div className="share-emoji-pattern">
            {previousGameStreak > 0 && Array(previousGameStreak).fill('🟩').map((emoji, index) => (
              <span key={index} className="emoji">{emoji}</span>
            ))}
            <span className="emoji">🟥</span>
          </div>
        </div>
        
        <div className="share-buttons">
          <button 
            className="share-button copy" 
            onClick={handleCopyToClipboard}
          >
            {copied ? (
              <>
                <CheckIcon />
                <span>Copied</span>
              </>
            ) : (
              <>
                <ContentCopyIcon />
                <span>Copy to Clipboard</span>
              </>
            )}
          </button>
          <button className="share-button download" onClick={handleDownloadImage}>
            <DownloadIcon />
            <span>Download Image</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal; 